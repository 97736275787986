import * as React from "react";
import "../styles/globals.css";
import "./index.scss";

import { StaticImage } from "gatsby-plugin-image";
import {
	OrderMrDeliveryButton,
	OrderUberEatsButton,
} from "../components/bookingButtons";

import NavbarWeb from "../components/navbarWeb";
import MobileNav from "../components/navbarMobile";
import Footer from "../components/footer";

const TakeAwayPage = () => {
	return (
		<main id="Takeaway">
			<NavbarWeb></NavbarWeb>
			<div className="mobileNavContainer">
				<div className="navLogo">
					<StaticImage
						src="../images/Kyoto-Brush-White.png"
						placeholder="blurred"
						width={346}
						height={720}
						formats={["auto", "webp", "avif"]}
						alt="Kyoto Brush White"
						transformOptions={{ fit: "cover", cropFocus: "attention" }}
					/>
				</div>
				<MobileNav></MobileNav>
			</div>
			<header className="hero-banner">
				<h1>TAKE AWAY</h1>
				<div className="image-container">
					<StaticImage
						src="../images/takeaway1.jpg"
						placeholder="tracedSVG"
						width={1250}
						height={400}
						formats={["auto", "webp", "avif"]}
						alt="Koshi sushi chef"
						transformOptions={{ fit: "cover", cropFocus: "attention" }}
					/>
				</div>
			</header>

			<section className="order-section">
				<p>
					You are welcome to use our partner's platforms to order a takeaway
					from our restaurant:
				</p>
				<OrderUberEatsButton></OrderUberEatsButton>
				<OrderMrDeliveryButton />
			</section>

			<Footer></Footer>
			<style>{`
                footer .bookingButtons {
                    display: none;
                }
            `}</style>
		</main>
	);
};

export default TakeAwayPage;

export const Head = () => (
	<>
		<title>Takeaway</title>
		<meta
			name="description"
			content="Get your favorite Japanese and sushi dishes from Kyoto Garden, available for pickup or Uber Eats delivery. Order online and enjoy the flavors of Japan in the comfort of your own home."
		/>
	</>
);
